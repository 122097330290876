import Calculator from "./Calculator";
import "./App.css";
const App=()=>{ 
    return (
        <div className="calculator">
            <h1>App Component</h1>
             <Calculator />  
             
        </div>
     );   
}
export default App;