import {useState} from 'react';
import './Calculator.css';
import All_no from './all_no';
const Calculator=()=>{
    const[data,setData]=useState({fn:"",opt:"",res:""});
    const handleNo=(evt)=>{        
        if(data.res==="")
        {
            setData({...data,res:evt.target.value});
        }
        else 
        {
            setData({...data,res:data.res+evt.target.value});
        }

        
    }
    const handleOpt=(evt)=>{
        setData({...data,fn:data.res,res:"",opt:evt.target.value});
    }

    const handleEql=(evt)=>{
        let a,b,c;
        a=Number(data.fn);
        b=Number(data.res);
        switch(data.opt)
        {
            case "+":
                c = a + b;
                setData({ ...data, fn: "", opt: "", res: c });
                break;
            case "-":
                c = a - b;
                setData({ ...data, fn: "", opt: "", res: c });
                break;
            case "*":
                c = a * b;
                setData({ ...data, fn: "", opt: "", res: c });
                    break;
            case "/":
                c = a / b;
                setData({ ...data, fn: "", opt: "", res: c });
                break;
            case "CLR": setData({ fn: "", opt: "", res: "" });
                break;
        }
       // setData({...data,fn:"",opt:"",res:c});
    }
   
    return(
        <>
            <input type="text" readOnly value={data.res} /> <br />
            <All_no clk={handleNo} />
            <input type="button" value="0" onClick={handleNo}  />

            <input type="button" value="+" onClick={handleOpt}  />
            <input type="button" value="-" onClick={handleOpt}  /> <br />
            <input type="button" value="*" onClick={handleOpt}  />
            <input type="button" value="/" onClick={handleOpt}  />

            <input type="button" value="=" onClick={handleEql}  />
            <input type="button" value="CLR" onClick={handleOpt}  />

        </>
    );
}
export default Calculator;