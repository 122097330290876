const All_no = (props) => {
    const list = []

    for (let i = 1; i <= 9; i++) {
        list.push(

            <input type="button" value={i} onClick={props.clk} key={i }/>
              
             )
    }

    return (
        <>
            {list}
        </>
    )
}
export default All_no;
